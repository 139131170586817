.privacy-policy {
  padding: 20px 7rem;
  background-color: #eaeaea;
}

.heading {
  font-family: 'Poppins', sans-serif;
  font-weight: bold;
  font-size: 24px;
  margin-bottom: 20px;
}

.subheading {
  font-family: 'Poppins', sans-serif;
  font-weight: bold;
  font-size: 20px;
  margin-top: 20px;
}

.paragraph {
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 16px;
  margin: 10px 0;
}

/* Add responsive design */
@media (max-width: 768px) {
  .privacy-policy {
    padding: 10px 2rem; /* Adjust padding for smaller screens */
  }
}
