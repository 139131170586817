.home {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4rem 6rem;
  min-height: 65vh;
  background-color: #eaeaea;
  flex-wrap: wrap;
}

.text-container {
  flex: 1;
  padding-right: 6rem;
  min-width: 300px;
}

.heading {
  font-family: 'Poppins', sans-serif;
  font-weight: 800;
  font-size: 6rem;
  color: #333;
  margin-bottom: 1.5rem;
  line-height: 1.1;
  letter-spacing: 1px;
}

.paragraph {
  font-family: 'Poppins', sans-serif;
  font-weight: bold;
  font-size: 2.5rem;
  color: #666;
  margin-bottom: 0; /* Removed bottom margin */
  line-height: 1.3;
}

.fitness-image {
  max-width: 45%;
  height: auto;
  object-fit: cover;
  border-radius: 8px;
}

@media (max-width: 1200px) {
  .home {
    padding: 3rem 4rem;
  }

  .text-container {
    padding-right: 4rem;
  }

  .heading {
    font-size: 5rem;
  }

  .paragraph {
    font-size: 2.2rem;
  }
}

@media (max-width: 768px) {
  .home {
    flex-direction: column;
    text-align: center;
    padding: 2rem;
    justify-content: center;
    gap: 0;
  }

  .text-container {
    padding-right: 0;
    margin-bottom: 0;
  }

  .heading {
    font-size: 4.5rem;
    margin-bottom: 1rem;
  }

  .paragraph {
    font-size: 2.3rem;
    margin-bottom: 0; /* Removed bottom margin */
  }

  .fitness-image {
    max-width: 100%;
    margin: 0; /* Removed all margins */
    display: block; /* Ensures no extra space */
  }
}

@media (max-width: 480px) {
  .heading {
    font-size: 3.5rem;
  }

  .paragraph {
    font-size: 2rem;
  }
}