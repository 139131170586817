.footer {
  background-color: white;
  padding: 2rem;
  border-top: 1px solid #eaeaea;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footer-logo {
  height: 40px;
  width: auto;
}

.footer-content {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 1rem;
}

.footer-icons {
  display: flex;
  gap: 1.5rem;
}

.icon {
  font-size: 24px;
  color: #333;
  transition: color 0.2s ease;
}

.icon:hover {
  color: #666;
}

.footer-phone {
  color: #333;
  text-decoration: none;
  font-size: 16px;
  transition: color 0.2s ease;
}

.footer-phone:hover {
  color: #666;
}

@media (max-width: 768px) {
  .footer {
    flex-direction: column;
    gap: 1.5rem;
    text-align: center;
  }

  .footer-content {
    align-items: center;
  }
}