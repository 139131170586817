.header {
  background-color: white;
  padding: 1rem 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #eaeaea;
}

.logo-link {
  display: inline-block;
}

.logo {
  height: 40px;
  width: auto;
}

.nav {
  display: flex;
  gap: 2rem;
}

.nav-link {
  color: #333;
  text-decoration: none;
  font-size: 16px;
  transition: color 0.2s ease;
}

.nav-link:hover {
  color: #666;
}

.nav-link.active {
  font-weight: bold;
}